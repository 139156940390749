// duplicate of server-side componentized.rb#llm_readable_text
export function getLessonTextFormattedForTutorbot(mainText: string, modalTexts: string[]): string {
    let i = -1;
    const textWithInlineModals = mainText.replace(/\[\[(.*?)\]\]/g, match => {
        i += 1;
        return `${match} (${textWithoutMarkup(modalTexts[i] || '')})`;
    });

    return textWithoutMarkup(textWithInlineModals);
}

export default getLessonTextFormattedForTutorbot;

function textWithoutMarkup(text: string): string {
    // In places where mathjax is used simply to wrap things like numbers and dollar signs, just remove the mathjax separators (see http://localhost:3001/editor/lesson/e4eb28fd-68dc-4276-9770-35a1828da621/edit?frame=4ba0e480-2d84-4507-a362-ee0e12a93c82)
    text = text.replace(/%%([$.+/\\\-()(0-9)]+)%%/g, '$1');

    // Handle custom stuff
    // Leave Modals inline wrapped in parentheses
    text = text.replace(/\[\[(.*?)\]\]/g, '$1');

    // Inline images ![...]
    // Display images !![...]
    // (see processes_inline_images_editor.js)
    text = text.replace(/(!!?)\[(.*?)\]({[^}]*})?/g, '');

    // Centering
    text = text.replace(/^->(.*?)<-$/g, '$1');

    // In the server-side text_without_markup, we also remove markdown, but I don't see why we need to.
    // We'd lose links, and it shouldn't hurt.
    return text;
}
