import { type LinkToLessonInStream } from 'Lessons';
import { isHelpscoutSourceLocation } from '../../utils/sourceLocationHelpers';
import {
    type DisplayableNonLessonFrameSourceLocation,
    type HelpscoutArticleSourceLocation,
} from '../../TutorBot.types';

export function isFirstLinkFromHelpScoutCollection(
    nonLessonLink: DisplayableNonLessonFrameSourceLocation,
    nonLessonLinks: DisplayableNonLessonFrameSourceLocation[],
) {
    if (!isHelpscoutSourceLocation(nonLessonLink)) return false;
    const helpscoutLink = nonLessonLink as HelpscoutArticleSourceLocation;
    const helpscoutLocations: HelpscoutArticleSourceLocation[] = nonLessonLinks.filter(l =>
        isHelpscoutSourceLocation(l),
    ) as HelpscoutArticleSourceLocation[];
    return helpscoutLocations.filter(l => l.collectionId === helpscoutLink.collectionId).indexOf(helpscoutLink) === 0;
}

export function isFirstLessonLinkInStream(lessonLink: LinkToLessonInStream, lessonLinks: LinkToLessonInStream[]) {
    return lessonLinks.filter(l => l.streamId === lessonLink.streamId).indexOf(lessonLink) === 0;
}

export const linkStyles =
    'transition focus-visible:outline focus-visible:outline-1 quantic:text-coral quantic:hover:text-coral-hover valar:text-blue valar:hover:text-blue-dark';
