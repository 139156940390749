import angularModule from 'Translation/angularModule/scripts/translation_module';
import { SupportedLocales } from '../../index';

angularModule.factory('translationErrorLoggerUtility', [
    '$injector',

    $injector => {
        const ErrorLogService = $injector.get('ErrorLogService');
        const Locale = $injector.get('Locale');
        const $translate = $injector.get('$translate');

        return {
            logTranslationError(message, extra = {}) {
                const extras = angular.merge(
                    {
                        serverDeterminedLocale: window.serverDeterminedLocale,
                        supportedLocales: SupportedLocales,
                        preferredCode: Locale.preferredCode,
                        translateIsReady: $translate.isReady(),
                        translateUse: $translate.use(false),
                    },
                    extra,
                );
                ErrorLogService.notify(message, undefined, extras);
            },
        };
    },
]);
