import { react2Angular } from 'FrontRoyalReact2Angular';
import { VoiceCoursePractice } from './VoiceCoursePractice';

const angularModule = angular.module('VoiceCoursePractice', []);

angularModule.component(
    'voiceCoursePractice',
    react2Angular(VoiceCoursePractice, ['tokenAttributes', 'previousLessonOptions'], ''),
);

export { angularModule };
