import { type ErrorLogService } from 'ErrorLogging';
import { HelpScoutCollectionId } from '../TutorBot.types';

export const helpScoutCollectionTitles = {
    'Student Handbook': [
        HelpScoutCollectionId.QuanticStudentHandbook,
        HelpScoutCollectionId.ValarStudentHandbook,
        HelpScoutCollectionId.ExecEdStudentHandbook,
    ],
    'Student Resources': [
        HelpScoutCollectionId.ValarStudentResources,
        HelpScoutCollectionId.ExecEdStudentResources,
        HelpScoutCollectionId.ValarResourcesStudentResources,
    ],
    'Student Resources - MBA & EMBA': [
        HelpScoutCollectionId.QuanticStudentResourcesMbaEmba,
        HelpScoutCollectionId.QuanticResourcesStudentResourcesMbaEmba,
    ],
    'Student Resources - MSBA': [
        HelpScoutCollectionId.QuanticStudentResourcesMsba,
        HelpScoutCollectionId.QuanticResourcesStudentResourcesMsba,
    ],
    'Student Resources - MSSE': [
        HelpScoutCollectionId.QuanticStudentResourcesMsse,
        HelpScoutCollectionId.QuanticResourcesStudentResourcesMsse,
    ],
    General: [
        HelpScoutCollectionId.QuanticGeneral,
        HelpScoutCollectionId.ValarGeneral,
        HelpScoutCollectionId.ExecEdGeneral,
    ],
    Applicants: [
        HelpScoutCollectionId.QuanticApplicants,
        HelpScoutCollectionId.ValarApplicants,
        HelpScoutCollectionId.ExecEdApplicants,
    ],
};

export function helpScoutCollectionTitle(
    collectionId: HelpScoutCollectionId,
    errorLogService: typeof ErrorLogService | null = null,
): string {
    let title = '';

    Object.entries(helpScoutCollectionTitles).forEach(([helpscoutCollectionTitle, includedCollectionIds]) => {
        if (includedCollectionIds.includes(collectionId)) {
            title = helpscoutCollectionTitle;
        }
    });

    if (title === '' && errorLogService) {
        errorLogService.notifyInProd(new Error(`Unknown HelpScout collection ID: ${collectionId}`), null, {
            collectionId,
        });
    }
    return title;
}
