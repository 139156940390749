import angularModule from 'Translation/angularModule/scripts/translation_module';

angularModule.factory('translationLoader', [
    '$injector',
    // The fact that this is a promise is a weird by-product of how angular-translate is setup.
    // We have translations on the window.locales object so should be able to do this synchronously,
    // but since we lazyLoad certain JavaScript in the client (Reports), we need to refresh translation
    // tables via an asynchronous loader after said JavaScript adds more translations to the window object.
    () => options => Promise.resolve(window.locales[options.key]),
]);
