import { type Dispatch, type SetStateAction, useEffect, type FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generateGuid } from 'guid';
import { getActiveConversationId, getActiveConversationGreetingMessages, chatActions } from '../redux/chat';
import { type PretendAiMessage } from '../TutorBot.types';
import { createPretendAiMessage } from '../utils/ChatMessage';

const initialGreetingKeys = ['niceToMeetYou', 'getToKnowYou', 'newAroundHere', 'doYouHaveAQuestion'] as const;

type Props = {
    name: string;
    setHasSeenInitialGreeting: Dispatch<SetStateAction<boolean | undefined>>;
};

export const InitialGreetingMessage: FC<Props> = ({ name, setHasSeenInitialGreeting }) => {
    const greetingIndex = useRef(1);
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const { t } = useTranslation('back_royal');
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);
    const greetingMessages = useSelector(getActiveConversationGreetingMessages);
    const greetingMessagesLength = greetingMessages.length;

    // Set up the initial message (index 0)
    useEffect(() => {
        if (greetingMessagesLength) return;

        const initialMessage: PretendAiMessage = createPretendAiMessage({
            conversationId: activeConversationId!,
            content: t(`greetingMessage.greetingMessage.${initialGreetingKeys[0]}`, { name }),
        });
        dispatch(chatActions.addMessage({ message: initialMessage }));
    }, [name, t, dispatch, activeConversationId, greetingMessagesLength]);

    // Set up the INTERVAL to show the subsequent messages (indexes 1+)
    useEffect(() => {
        if (intervalRef.current) return () => null;

        const _intervalRef = setInterval(() => {
            const message: PretendAiMessage = createPretendAiMessage({
                id: generateGuid(),
                conversationId: activeConversationId!,
                content: t(`greetingMessage.greetingMessage.${initialGreetingKeys[greetingIndex.current]}`, { name }),
            });

            dispatch(chatActions.addMessage({ message }));

            if (greetingIndex.current < initialGreetingKeys.length - 1) {
                greetingIndex.current += 1;
            } else {
                clearInterval(_intervalRef);
                intervalRef.current = null;
                setHasSeenInitialGreeting(true);
            }
        }, 1350);

        intervalRef.current = _intervalRef;

        return () => {
            clearInterval(_intervalRef);
            intervalRef.current = null;
        };
    }, [activeConversationId, setHasSeenInitialGreeting, dispatch, name, t]);

    // This component is only rendered when we are supposed to show initial greeting messages.
    // It doesn't itself render anything - after a refactor it now just dispatches the messages to the store because we
    // are wanting to treat these messages as part of the actual conversation history/context.
    return null;
};
