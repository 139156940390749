import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { useLockedAiAdvisorConfig } from '../useLockedAiAdvisorConfig';
import { getActiveConversationId, chatActions, getActiveConversationUiContext } from '../redux/chat';
import { type PretendAiMessage } from '../TutorBot.types';
import { createPretendAiMessage } from '../utils/ChatMessage';

export const LockedAiAdvisorMessage: FC = () => {
    const [greetingIndex, setGreetingIndex] = useState(0);
    const prevGreetingIndex = usePrevious(greetingIndex);
    const uiContext = useSelector(getActiveConversationUiContext);
    const { config } = useLockedAiAdvisorConfig(uiContext);
    const { t } = useTranslation('back_royal');
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);

    useEffect(() => {
        const greetingMessage = config?.greetingMessages[greetingIndex];

        if (!greetingMessage || prevGreetingIndex === greetingIndex) return;

        setTimeout(
            () => {
                const message: PretendAiMessage = createPretendAiMessage({
                    conversationId: activeConversationId!,
                    content: t(greetingMessage.key, greetingMessage.params ?? {}),
                });

                dispatch(chatActions.addMessage({ message }));
                setGreetingIndex(prev => prev + 1);
            },
            greetingIndex === 0 ? 0 : 1350,
        );
        // tried adding a cleanup function to clear the timeout, but it was running too often and prevented messages
    }, [greetingIndex, setGreetingIndex, prevGreetingIndex, config, activeConversationId, dispatch, t]);

    // This component is only rendered when we are supposed to show locked-state greeting messages.
    // It doesn't itself render anything - it just dispatches the messages to the store because we
    // are wanting to treat these messages as part of the actual conversation history/context.
    return null;
};
