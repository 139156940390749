import { type ProgramInclusion } from 'ProgramInclusion';
import { type TuitionPlan, TuitionPlanFrequency } from './index';

export const oneTimePlan = {
    id: '4a6650e7-9875-4d09-85ae-39552a6671d2',
    baseTuition: 13300.0,
    name: '$13300 with one-time payment discount',
    tag: 'EMBA51+,MBA51+',
    frequency: TuitionPlanFrequency.Once,
    numIntervals: 1,
} as TuitionPlan;

export const annualPlan = {
    id: '1d1ea887-7d68-4afe-834c-13dcd717dc65',
    baseTuition: 13300.0,
    name: '$13300 with annual payment plan',
    tag: 'EMBA51+,MBA51+',
    frequency: TuitionPlanFrequency.Annual,
    numIntervals: 2,
} as TuitionPlan;

export const monthlyPlan = {
    id: 'c3e80cb2-6b20-4232-96eb-04e12d8c3f32',
    baseTuition: 13300.0,
    name: '$13300 with monthly payment plan',
    tag: 'EMBA51+,MBA51+',
    frequency: TuitionPlanFrequency.Monthly,
    numIntervals: 14,
} as TuitionPlan;

export const fullScholarshipPlan = {
    id: '86541be3-55d7-43da-b71e-9b2b20a1fa90',
    baseTuition: 13300.0,
    name: 'Full Scholarship',
    tag: 'EMBA51+,MBA51+',
    frequency: TuitionPlanFrequency.FullScholarship,
    numIntervals: null,
} as TuitionPlan;

export const getStubTuitionPlanForProgramInclusion = (programInclusion: ProgramInclusion): TuitionPlan => {
    const tuitionPlan = [oneTimePlan, annualPlan, monthlyPlan, fullScholarshipPlan].find(
        tp => tp.id === programInclusion.tuitionPlanId,
    );

    if (!tuitionPlan) {
        throw new Error(
            `Stub tuition plan not found for program inclusion with tuition plan ID ${programInclusion.tuitionPlanId}`,
        );
    }

    return tuitionPlan;
};

export const threeTuitionPlans = [oneTimePlan, annualPlan, monthlyPlan];
