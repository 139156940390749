import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@nextui-org/react';
import { type ContentProps, Popover } from 'Popover';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { Text } from 'Text';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { memo, useEffect, useState } from 'react';
import { LaunchBotButton } from './LaunchBotButtons';
import { type AiAdvisorTooltipProps } from './AiAdvisorTooltip.types';

const StarEmoticon = '\u{1F31F}';

const TooltipContent = ({ titleProps, close }: ContentProps) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'aiAdvisorTooltip.aiAdvisorTooltip' });
    const EventLogger = useEventLogger();

    return (
        <div className="relative p-5 sm:max-w-72">
            <Button
                isIconOnly
                size="lg"
                className={clsx(
                    'data-[focus-visible=true]:outline-hidden absolute -right-4',
                    '-top-4 h-10 w-10 min-w-10 rounded-full border border-solid',
                    'border-slate-grey-200 bg-white shadow-none',
                )}
                onPress={() => {
                    EventLogger.log('tutorbot:dismissed_tooltip', {});
                    close();
                }}
            >
                <FontAwesomeIcon icon={faTimes} className="text-lg text-black" />
            </Button>
            <div {...titleProps} className="mb-2.5 inline-flex">
                <Text as="h4" className="my-auto me-1.5">
                    {StarEmoticon}
                </Text>
                <Text as="h4">{t('curious')}</Text>
            </div>
            <div>
                <Text>{t('hereToHelp')}</Text>
            </div>
        </div>
    );
};

export const AiAdvisorTooltip = memo(
    ({ onClose, backwardsClass, continueButtonVisible, buttonKey, launchBot, allowOpen }: AiAdvisorTooltipProps) => {
        const { isMobile } = useIsMobileMixin();

        // Because of the way the continue button animates on mobile we create
        // this state variable to tell nextui that the popover should move;
        const [triggerAnimationKey, setTriggerAnimationKey] = useState(0);

        useEffect(() => {
            let timeout: NodeJS.Timeout;

            if (isMobile && continueButtonVisible && allowOpen) {
                let duration = 1000; /* This is how long the continue button takes to animate */
                const intervalMs = 10;
                timeout = setInterval(() => {
                    if (duration <= 1000) {
                        setTriggerAnimationKey(v => v + 1);
                        duration += intervalMs;
                    } else {
                        clearInterval(timeout);
                    }
                }, intervalMs);
            }

            return () => {
                clearInterval(timeout);
            };
        });

        return (
            <Popover
                Content={TooltipContent}
                onClose={onClose}
                classNames={{
                    base: ['max-w-[calc(100%_-_25px)] sm:max-w-full', 'data-[placement=top-start]:before:left-[105px]'],
                    content: 'p-0',
                }}
                borderColor="slate-grey-200"
                closeOnOverlayClick={false}
                openDelayMs={isMobile && continueButtonVisible ? 1000 : 100}
                isOpenedInitially
                allowOpen={allowOpen}
                placement={isMobile ? 'top' : 'top-start'}
                updatePositionDeps={[triggerAnimationKey]}
            >
                <LaunchBotButton
                    backwardsClass={backwardsClass}
                    continueButtonVisible={continueButtonVisible}
                    buttonKey={buttonKey}
                    launchBot={launchBot}
                />
            </Popover>
        );
    },
);
