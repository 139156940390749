import 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-once';
import 'angular-moment';
import { DefaultLocale, SupportedLocales } from '../../index';

export default angular
    .module('Translation', ['pascalprecht.translate', 'angularMoment'])

    // In instances where our application was bundled with English, we should
    // use window.serverDeterminedLocale when trying to figure out which locale
    // to use. If our application was bundles with a non-English locale, we should
    // give preference to the bundled locale.
    .constant('Translation.UseServerDeterminedLocale', DefaultLocale === 'en')

    .config([
        '$injector',
        $injector => {
            const $translateProvider = $injector.get('$translateProvider');
            const moment = $injector.get('moment');
            const UseServerDeterminedLocale = $injector.get('Translation.UseServerDeterminedLocale');

            const preferred = UseServerDeterminedLocale ? window.serverDeterminedLocale : DefaultLocale;
            const localeToUse = SupportedLocales.includes(preferred) ? preferred : SupportedLocales[0];

            //-----------------------------
            // Configure $translateProvider
            // https://angular-translate.github.io/docs/#/guide

            // Configure the translations that are currently available on the window object.
            SupportedLocales.forEach(locale => {
                $translateProvider.translations(locale, window.locales[locale]);
            });

            // Configure the asynchronous translation loader that will run if we call $translate.refresh().
            // We do this in route_asset_loader.js when we lazy load the reports module, which adds more translations
            // to the window object.
            $translateProvider.useLoader('translationLoader');
            $translateProvider.forceAsyncReload(true);
            $translateProvider.fallbackLanguage(DefaultLocale);

            // Set desired language based on the global 'locale' variable
            // set on the server-side using the 'Allow-Language' header. I wish we
            // could rely on the w3c variable 'navigator.language', but can be problematic
            // to rely on.
            // See http://stackoverflow.com/a/2678437/1747491
            // See http://stackoverflow.com/a/4079798/1747491
            // (We do not yet have access to Locale.preferredCode at this point, so there is a bit
            // of duplication here)
            $translateProvider.preferredLanguage(localeToUse);
            moment.updateLocale(localeToUse);

            // add support for pluralization and advanced MessageFormat syntax
            $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

            // sanitize interpolated values where possible
            $translateProvider.useSanitizeValueStrategy('escapeParameters');

            // "translate-until-keys-stabilize" functionality
            $translateProvider.useMissingTranslationHandler('missingTranslationHandler');
        },
    ]);
