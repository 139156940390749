import { isNonRelayEmail } from 'EmailInput';
import { TuitionAndRegistrationSection, getTuitionAndRegistrationSection } from 'TuitionAndRegistrationSection';
import 'ExtensionMethods/array';
import { InstitutionId } from 'Institutions';
import { regionAwareImageCdnRewriter } from 'regionAwareImage';
import { preSignupValues, sha256 } from 'TinyEventLogger';
import { AoiRecordType } from 'ProgramAoi';
import camelCaseKeys from 'camelcase-keys';
import transformKeyCase from 'Utils/transformKeyCase';
import { subscriptionPastDue } from 'Subscription';
import { hasFailed, willNotGraduate } from 'ProgramInclusion';
import { ProgramApplicationStatus } from 'ProgramApplication';
import { DisconnectedError } from 'DisconnectedError';
import memoizeOne from 'memoize-one';
import { getProgramConfigValue } from 'Program';
import Cookies from 'js-cookie';
import { getProgramFamilyFormDatum } from 'ProgramFamilyFormData';
import {
    getNotJoiningProgram,
    getProgramInclusion,
    getIncludedOrGraduatedProgramInclusion,
    getCurrentlyPreventedFromSubmittingApplication,
    getAoiRecord,
    USER_ATTRS_FOR_CASE_TRANSFORM as AOI_ATTRS_FOR_CASE_TRANSFORM,
    getTuitionContract,
    getIsCurrentOrHasCompletedActiveProgram,
    getHasPendingProgramApplication,
    getHasPendingAdmissionOfferOrIsNotYetCurrent,
    getIsNotJoiningProgramOrHasBeenExpelled,
    GraduationStatus,
    getGraduationStatus,
    getHasFailedProgram,
    getHasOfferOrIncludedOrCompleted,
    getCohort,
    getUserProgramState,
    getUnverifiedForActiveIdVerificationPeriod,
    getPastDueForIdVerification,
    getAdmissionOffer,
    getUserObjectForSave,
} from '../../../index';
import angularModule from '../users_module';

// iguana service wrapper class
// currently only opening up /api/users.json which returns info about the current user
angularModule.factory('User', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const $timeout = $injector.get('$timeout');
        const $q = $injector.get('$q');
        const $http = $injector.get('$http');
        const Cohort = $injector.get('Cohort');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const $window = $injector.get('$window');
        const UserProgressLoader = $injector.get('UserProgressLoader');
        const ClientStorage = $injector.get('ClientStorage');
        const UserIdVerification = $injector.get('UserIdVerification');
        const ErrorLogService = $injector.get('ErrorLogService');
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const $route = $injector.get('$route');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const Playlist = $injector.get('Playlist');
        const DialogModal = $injector.get('DialogModal');

        const getIguanaCohortFromCamelCaseAttrs = memoizeOne(cohortAttrs =>
            Cohort.new(transformKeyCase(cohortAttrs, { to: 'snakeCase' })),
        );

        return Iguana.subclass(function userIguanaSubclass() {
            this.setCollection('users');
            this.alias('User');
            this.setIdProperty('id');
            this.embedsOne('career_profile', 'CareerProfile');
            this.embedsOne('active_institution', 'Institution');
            this.embedsMany('s3_transcript_assets', 'S3TranscriptAsset');
            this.embedsMany('s3_english_language_proficiency_documents', 'S3EnglishLanguageProficiencyDocument');
            this.embedsMany('s3_supplemental_enrollment_documents', 'S3SupplementalEnrollmentDocument');
            this.embedsMany('user_id_verifications', 'UserIdVerification');
            this.embedsMany('project_progress', 'ProjectProgress'); // used in admin_gradebook
            this.embedsMany('student_email_addresses', 'StudentEmailAddress');

            // Since the filters for index calls can get really long,
            // we use a post to avoid having get urls that go beyond browser
            // limits.
            this.overrideAction('index', { method: 'POST', path: 'index' });

            this.setCallback('after', 'copyAttrsOnInitialize', function copyAttrsOnInitializeCallback() {
                this.cohort_section_offers ||= [];
                this.student_email_addresses ||= [];
                this.s3_english_language_proficiency_documents ||= [];
                this.s3_transcript_assets ||= []; // legacy
            });

            this.setCallback('after', 'copyAttrs', function copyAttrsCallback() {
                // Any time we copyAttrs on a User (after initialize or when pushing onto the user via
                // pushUpdatedPropertiesOntoCurrentUser in CurrentUserInterceptor), we want to copy all
                // of these underscore properties to their camelCase equivalents.
                // We do this so we're forward compatible with RTKQuery.

                transformKeyCase(this, {
                    to: 'camelCase',
                    keys: [...AOI_ATTRS_FOR_CASE_TRANSFORM, 'exec_ed_eligibility_bundle', 'signable_documents'],
                    destructive: true,
                });

                // The following lists are duplicated. The snake-cased versions are iguana objects that are still
                // used in angular code. The camel-cased versions are vanilla objects that are used in es6 functions.
                // We should eventually get rid of the snake-cased versions and the associated iguana classes.
                this.userIdVerifications = this.user_id_verifications?.map(verification => camelCaseKeys(verification));
            });

            this.extend({
                maxEnglishLanguageProficiencyDocuments: 7,

                // FIXME: See discussion regarding user.programType at https://trello.com/c/QFVa6l5W
                programTypes: Cohort.programTypes.concat([
                    { key: 'demo', label: 'Demo' },
                    { key: 'external', label: 'External' },
                ]),

                mapClientSortToServerSort(clientSort) {
                    switch (clientSort) {
                        default:
                            return clientSort;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'inEfficacyStudy', {
                get() {
                    return _.some(this.institutionNames(), name => name.indexOf('EFFICACY') === 0);
                },
            });

            Object.defineProperty(this.prototype, 'hasLearnerAccess', {
                value: true,
            });

            Object.defineProperty(this.prototype, 'hasBotPageAccess', {
                get() {
                    return getProgramConfigValue(this.programType, 'supportsBotPage');
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isNominee', {
                get() {
                    return !!this.peer_nomination?.id;
                },
            });

            Object.defineProperty(this.prototype, 'canUseBotPageWithoutProgramInclusion', {
                get() {
                    return this.can_use_bot_page_without_program_inclusion;
                },
            });

            Object.defineProperty(this.prototype, 'hasNominationsAccess', {
                get() {
                    return getIsCurrentOrHasCompletedActiveProgram(this) && this.relevantCohort?.supportsNominationsTab;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasEditCareerProfileAccess', {
                get() {
                    // FIXME: Ultimately I think we should move to a world with no concept of
                    // programType on a user directly. If and when we do, this will need
                    // to look at the user's last application instead.
                    // See https://trello.com/c/QFVa6l5W
                    return (
                        (this.can_edit_career_profile || getHasOfferOrIncludedOrCompleted(this)) &&
                        Cohort.supportsEditingCareerProfile(this.programType)
                    );
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'canUploadAvatarInAccountPage', {
                get() {
                    return Cohort.supportsAvatarUploadInAccountPage(this.programType);
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'hasStudentNetworkAccess', {
                get() {
                    return this.has_student_network_access;
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'hasAccessToFullStudentNetworkProfiles', {
                get() {
                    return this.has_access_to_full_student_network_profiles;
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'hasStudentNetworkEventsAccess', {
                get() {
                    return this.has_student_network_events_access;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'inIsolatedNetworkCohort', {
                get() {
                    return this.relevantCohort && !!this.relevantCohort.isolated_network;
                },
            });

            Object.defineProperty(this.prototype, 'hasEverApplied', {
                get() {
                    return this.has_ever_applied;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasValidEmail', {
                get() {
                    return this.email && this.emailDomain !== 'example.com';
                },
            });

            Object.defineProperty(this.prototype, 'isEmailValidForStudentNetwork', {
                get() {
                    return !isNonRelayEmail(this.email);
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'missingRequiredStudentNetworkEmail', {
                get() {
                    return !!(
                        this.pref_student_network_privacy === 'full' &&
                        !this.student_network_email &&
                        !this.isEmailValidForStudentNetwork &&
                        this.hasAccessToFullStudentNetworkProfiles
                    );
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'canViewLanguageSwitcher', {
                get() {
                    return this.hasSuperEditorAccess;
                },
            });

            Object.defineProperty(this.prototype, 'canLaunchExamOutsideOfLimits', {
                get() {
                    return this.hasAdminAccess;
                },
            });

            // This property indicates that, in general, the user has the right to
            // submit program applications. But, due to their current state, they may not
            // currently be allowed to submit an application. See canCurrentlySubmitProgramApplication
            Object.defineProperty(this.prototype, 'canSubmitProgramApplications', {
                get() {
                    // Demo users are assigned the current mba cohort as their relevant cohort,
                    // but the cannot apply to smartly
                    return (
                        !!this.relevantCohort &&
                        this.career_profile &&
                        getProgramConfigValue(this.programType, 'supportsManuallyApplyingToProgram')
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'canCurrentlySubmitProgramApplication', {
                get() {
                    const val =
                        this.canSubmitProgramApplications && !getCurrentlyPreventedFromSubmittingApplication(this);

                    if (!val) {
                        return false;
                    }

                    const programApplication = getAoiRecord(this, AoiRecordType.ProgramApplication);
                    if (programApplication && programApplication.cohortId === this.relevantCohort.id) {
                        // See User::AllowReapplication#available_program_field_options for how we make sure
                        // a user never gets in this situation. The reason we're calling 'notify' here rather than just
                        // returning false is that, if we assume this situation never happens, then we can assume that,
                        // if canEventuallyReapply=true and canCurrentlySubmitProgramApplication=false, then there must be
                        // a reapplicationDate in the future. See student_dashboard_program_box_dir.js for a place where we
                        // make that assumption. Maybe the best solution is to just handle that situation, even it isn't realistic
                        // right now, but that would require new locales and everything, so it didn't seem worth it at the moment.
                        ErrorLogService.notifyInProd(
                            'User is marked as being able to apply, but they already have an application for the promoted cohort.',
                        );
                    }
                    return true;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'canResetUsersExamTimer', {
                get() {
                    return this.hasAdminAccess;
                },
            });

            Object.defineProperty(this.prototype, 'accountId', {
                get() {
                    if (this.email) {
                        return this.email;
                    }
                    return this.id;
                },
            });

            Object.defineProperty(this.prototype, 'hasAdminAccess', {
                get() {
                    return this.roleName() === 'admin';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasReportsAccess', {
                get() {
                    return this.hasSuperEditorAccess || this.is_institutional_reports_viewer;
                },
            });

            Object.defineProperty(this.prototype, 'hasSuperReportsAccess', {
                get() {
                    return this.hasSuperEditorAccess;
                },
            });

            Object.defineProperty(this.prototype, 'hasSuperEditorAccess', {
                get() {
                    return this.roleName() === 'admin' || this.roleName() === 'super_editor';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasEditorAccess', {
                get() {
                    return (
                        this.roleName() === 'admin' ||
                        this.roleName() === 'editor' ||
                        this.roleName() === 'super_editor'
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasInterviewerAccess', {
                get() {
                    return this.roleName() === 'admin' || this.roleName() === 'interviewer';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasSuperEditorOrInterviewerAccess', {
                get() {
                    return (
                        this.roleName() === 'admin' ||
                        this.roleName() === 'super_editor' ||
                        this.roleName() === 'interviewer'
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'canShare', {
                get() {
                    return !this.hasExternalInstitution && !this.beta;
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'hasSamlProvider', {
                get() {
                    return this.has_saml_provider;
                },
                configurable: true, // for tests
            });

            Object.defineProperty(this.prototype, 'hasExternalInstitution', {
                get() {
                    return this.institutions?.some(i => i.external);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'ghostMode', {
                get() {
                    return this.$$ghostMode;
                },
                set(val) {
                    this.$$ghostMode = val;
                },
            });

            Object.defineProperty(this.prototype, 'hideAllLessons', {
                get() {
                    return this.$$hideAllLessons || false;
                },
                set(val) {
                    this.$$hideAllLessons = val;
                },
            });

            Object.defineProperty(this.prototype, 'globalRole', {
                get() {
                    let existingGlobalRole;
                    if (this.roles) {
                        this.roles.forEach(role => {
                            if (role && role.resource_id === null && role.name !== 'cannot_create') {
                                existingGlobalRole = role;
                            }
                        });
                    }
                    return existingGlobalRole;
                },
                set(role) {
                    // remove any other global roles
                    let i = 0;
                    this.roles.forEach(_role => {
                        if (_role.resource_id === null && _role.name !== 'cannot_create') {
                            this.roles.splice(i, 1);
                        }
                        i += 1;
                    });
                    // add this global role
                    this.roles.push(role);
                },
            });

            Object.defineProperty(this.prototype, 'blueOcean', {
                get() {
                    const allGroups = this.groups.concat(this.groupsFromInstitutions);
                    return allGroups.length === 1 && allGroups[0].name === 'BLUEOCEAN';
                },
            });

            Object.defineProperty(this.prototype, 'beta', {
                get() {
                    const allGroups = this.groups.concat(this.groupsFromInstitutions);
                    return allGroups.length === 1 && (allGroups[0].name === 'BETA' || allGroups[0].name === 'BETA2');
                },
            });

            Object.defineProperty(this.prototype, 'lessonPermissions', {
                get() {
                    if (!this.$$lessonPermissions) {
                        // find any scoped roles for each lesson, return a hash keyed by lesson to populate
                        // into user admin page radio button selector per lesson
                        this.$$lessonPermissions = {};
                        this.roles.forEach(role => {
                            if (role && role.resource_id !== null && this.isScopedlessonPermission(role.name)) {
                                this.$$lessonPermissions[role.resource_id] = role.name;
                            }
                        });
                        return this.$$lessonPermissions;
                    }
                    return this.$$lessonPermissions;
                },
            });

            // global role: can create lessons
            Object.defineProperty(this.prototype, 'canCreateLessons', {
                get() {
                    let canCreate = true;
                    this.roles.forEach(role => {
                        // note: we only store a special role if access is not allowed (to save space)
                        if (role && role.resource_id === null && role.name === 'cannot_create') {
                            canCreate = false;
                        }
                    });
                    return canCreate;
                },
                set(canCreate) {
                    let i = 0;
                    let existingOverride = false;
                    this.roles.forEach(role => {
                        if (role && role.resource_id === null && role.name === 'cannot_create') {
                            existingOverride = true;
                            if (canCreate) {
                                // delete the cannot_create global override role if the user just re-enabled canCreate
                                this.roles.splice(i, 1);
                            }
                        }
                        i += 1;
                    });
                    // if the user is disabling can_create and the associated global override doesn't exist, add it
                    if (!canCreate && !existingOverride) {
                        // todo: iguana class role.new instead of hash?
                        const newRole = {
                            name: 'cannot_create',
                            resource_id: null,
                            resource_type: null,
                        };
                        this.roles.push(newRole);
                    }
                },
            });

            Object.defineProperty(this.prototype, 'groupsFromInstitutions', {
                get() {
                    const groupsSet = {};
                    (this.institutions || []).forEach(institution => {
                        (institution.groups || []).forEach(group => {
                            groupsSet[group.id] = group;
                        });
                    });
                    return Object.values(groupsSet) || [];
                },
            });

            Object.defineProperty(this.prototype, 'emailDomain', {
                get() {
                    return this.email ? this.email.split('@')[1] : undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isDemo', {
                get() {
                    return this.programType === 'demo';
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'hasAdditionalDetails', {
                get() {
                    return (
                        this.school || this.professional_organization || this.job_title || this.phone || this.country
                    );
                },
            });

            Object.defineProperty(this.prototype, 'hasSeenAccepted', {
                get() {
                    return this.has_seen_accepted;
                },
                set(val) {
                    this.has_seen_accepted = val;
                },
            });

            Object.defineProperty(this.prototype, 'isAuditing', {
                get() {
                    return !!getIncludedOrGraduatedProgramInclusion(this)?.auditing;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'includedWithoutApplication', {
                get() {
                    return !!(
                        getIncludedOrGraduatedProgramInclusion(this) && // Does it make sense to include graduated people here?
                        !getAoiRecord(this, AoiRecordType.AdmissionOffer) &&
                        !getAoiRecord(this, AoiRecordType.ProgramApplication)
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'lastActiveAoiRecordStatus', {
                get() {
                    const lastActiveRecord = getAoiRecord(this);
                    return lastActiveRecord?.status;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isCurrentOrHasCompletedActiveProgram', {
                get() {
                    return getIsCurrentOrHasCompletedActiveProgram(this);
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'isNotJoiningProgramOrHasBeenExpelled', {
                get() {
                    return getIsNotJoiningProgramOrHasBeenExpelled(this);
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'hasPendingProgramApplication', {
                get() {
                    return getHasPendingProgramApplication(this);
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'isNotJoiningPromotedCohort', {
                get() {
                    const aoiRecord = getAoiRecord(this);
                    return (
                        getNotJoiningProgram(this) &&
                        this.relevantCohort &&
                        aoiRecord?.cohortId === this.relevantCohort.id
                    );
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'isFailed', {
                get() {
                    return getHasFailedProgram(this);
                },
                configurable: true,
            });

            // TODO: refactor this so that we aren't relying on `getTuitionAndRegistrationSection` here. This feels icky
            // and we probably shouldn't be relying on this module to determine if the user needs to register or not.
            // This is probably easily expressed in AOI.
            Object.defineProperty(this.prototype, 'needsToRegister', {
                get() {
                    const section = getTuitionAndRegistrationSection(this);
                    return [
                        TuitionAndRegistrationSection.cohortRegistrationPlanSelection,
                        TuitionAndRegistrationSection.cohortRegistrationNoPlanSelection,
                        TuitionAndRegistrationSection.cohortRegistrationFullScholarship,
                    ].includes(section);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'graduationStatus', {
                get() {
                    return getGraduationStatus(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasEverBeenRejected', {
                get() {
                    return this.has_ever_been_rejected;
                },
                configurable: true, // specs
            });

            // FIXME: this should go away once we do https://trello.com/c/IgpkqD89
            Object.defineProperty(this.prototype, 'programType', {
                get() {
                    if (this.$$pendingProgramTypeSelected) {
                        return this.$$pendingProgramTypeSelected;
                    }
                    return this.program_type;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'testCompleteMessageKey', {
                get() {
                    // see also: `lesson_finish_screen-*.json`
                    return this.relevantCohort?.supportsAutograding ? 'your_test_score_auto' : null;
                },
            });

            Object.defineProperty(this.prototype, 'referralUrl', {
                get() {
                    return {
                        full: `${$window.ENDPOINT_ROOT}/cn/1/referral?by=${this.id}`,
                        partial: `cn/1/referral?by=${this.id}`,
                    };
                },
            });

            Object.defineProperty(this.prototype, 'preferredName', {
                get() {
                    return this.nickname || this.name;
                },
            });

            Object.defineProperty(this.prototype, 'mailingAddress', {
                get() {
                    let address = this.address_line_1;
                    address += this.address_line_2 ? `<br>${this.address_line_2}` : '';
                    address += `<br>${this.city}`;
                    address += this.state ? `, ${this.state}` : `, ${this.country}`;
                    address += this.zip ? ` ${this.zip}` : '';

                    return address;
                },
            });

            Object.defineProperty(this.prototype, 'officialTranscriptApprovedOrWaived', {
                get() {
                    return this.career_profile?.officialTranscriptApprovedOrWaived;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'officialTranscriptApproved', {
                get() {
                    return this.career_profile?.officialTranscriptApproved;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'careerProfileIndicatesUserShouldProvideTranscripts', {
                get() {
                    return !!(this.career_profile && this.career_profile.indicatesUserShouldProvideTranscripts);
                },
                configurable: true,
            });

            Object.defineProperty(
                this.prototype,
                'careerProfileIndicatesUserShouldUploadEnglishLanguageProficiencyDocuments',
                {
                    get() {
                        return !!(
                            this.career_profile &&
                            this.career_profile.indicates_user_should_upload_english_language_proficiency_documents
                        );
                    },
                    configurable: true,
                },
            );

            Object.defineProperty(this.prototype, 'aoiRecordIndicatesUserShouldProvideTranscripts', {
                get() {
                    return !!(
                        Cohort.supportsDocumentUpload(this.programType) &&
                        getAoiRecord(this) &&
                        !hasFailed(getProgramInclusion(this))
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasUploadedTranscripts', {
                get() {
                    return this.career_profile?.hasUploadedTranscripts;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'requiredTranscriptsUploadedColumnString', {
                get() {
                    if (!this.career_profile) {
                        return '';
                    }

                    const numUploaded = this.career_profile.numRequiredTranscriptsUploaded;
                    const numNotWaived = this.career_profile.numRequiredTranscriptsNotWaived;

                    if (numNotWaived > 0) {
                        return `${numUploaded}/${numNotWaived}`;
                    }
                    if (numNotWaived === 0) {
                        return '—';
                    }
                    return '';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'requiredTranscriptsApprovedColumnString', {
                get() {
                    if (!this.career_profile) {
                        return '';
                    }

                    const numApproved = this.career_profile.numRequiredTranscriptsApproved;
                    const numNotWaived = this.career_profile.numRequiredTranscriptsNotWaived;

                    if (numNotWaived > 0) {
                        return `${numApproved}/${numNotWaived}`;
                    }
                    if (numNotWaived === 0) {
                        return '—';
                    }
                    return '';
                },
                configurable: true,
            });

            // Transcripts are now associated with an education_experience, not the user itself
            // See https://trello.com/c/ErENgFKR
            Object.defineProperty(this.prototype, 'legacyTranscripts', {
                get() {
                    return this.s3_transcript_assets || [];
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasLegacyTranscripts', {
                get() {
                    return this.legacyTranscripts.length > 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasUploadedEnglishLanguageProficiencyDocuments', {
                get() {
                    return _.some(this.s3_english_language_proficiency_documents);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'identifiedForEnrollment', {
                get() {
                    return _.some(this.userIdVerifications);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'missingAddress', {
                get() {
                    if (this.country === 'US') {
                        return !this.address_line_1 || !this.state || !this.city || !this.zip;
                    }

                    return !this.address_line_1 || !this.country;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasValidEnrollmentStatusForStudentRecordUpload', {
                get() {
                    const aoiRecord = getAoiRecord(this);
                    return (
                        (getHasPendingAdmissionOfferOrIsNotYetCurrent(this) ||
                            getHasOfferOrIncludedOrCompleted(this) ||
                            aoiRecord?.status === ProgramApplicationStatus.SubmittedApplication) &&
                        getGraduationStatus(this) !== GraduationStatus.failed
                    );
                },
                configurable: true,
            });

            // This property relies on the user's pref program. Arguably, in the theoretical case
            // where a user has to upload docs for one program, but another program is currently the
            // pref one, we should still show the stuff in Settings -> Documents. This isn't a realistic
            // situation at the moment, and even if it were it doesn't see that critical.
            Object.defineProperty(this.prototype, 'recordsIndicateUserShouldProvideTranscripts', {
                get() {
                    const aoiRecord = getAoiRecord(this);
                    return !!(
                        this.hasValidEnrollmentStatusForStudentRecordUpload &&
                        this.careerProfileIndicatesUserShouldProvideTranscripts &&
                        Cohort.supportsDocumentUpload(aoiRecord?.programType)
                    );
                },
                configurable: true,
            });

            // See comment above recordsIndicateUserShouldProvideTranscripts about users in
            // multiple programs
            Object.defineProperty(
                this.prototype,
                'recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments',
                {
                    get() {
                        const aoiRecord = getAoiRecord(this);
                        return !!(
                            this.hasValidEnrollmentStatusForStudentRecordUpload &&
                            this.careerProfileIndicatesUserShouldUploadEnglishLanguageProficiencyDocuments &&
                            Cohort.requiresEnglishLanguageProficiency(aoiRecord?.programType)
                        );
                    },
                    configurable: true,
                },
            );

            // We used to duplicate this logic on the server, but the logic on the server has since
            // deviated from the client-side logic. See missing_official_transcripts? in user.rb.
            Object.defineProperty(this.prototype, 'missingOfficialTranscripts', {
                get() {
                    return !!(
                        !this.transcripts_verified &&
                        this.recordsIndicateUserShouldProvideTranscripts &&
                        this.career_profile?.missingOfficialTranscripts
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'missingEnglishLanguageProficiencyDocuments', {
                get() {
                    return (
                        !this.english_language_proficiency_documents_approved &&
                        this.recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments &&
                        !this.hasUploadedEnglishLanguageProficiencyDocuments
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'shouldBeRedirectedToApplication', {
                get() {
                    const normalLearner =
                        this.roleName() === 'learner' && !_.includes(['demo', 'external'], this.fallback_program_type);

                    const onboardingComplete =
                        this.skip_apply || !this.canSubmitProgramApplications ? true : !!getAoiRecord(this);

                    return normalLearner && !onboardingComplete;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'shouldVisitApplicationStatus', {
                get() {
                    const subscription = getTuitionContract(this)?.subscription;
                    const tuitionAndRegistrationSection = getTuitionAndRegistrationSection(this);

                    const needsToProvideBillingInfo =
                        // TODO: refactor this so that we aren't relying on `getTuitionAndRegistrationSection` here. This feels icky
                        // and we probably shouldn't be relying on this module to determine if the user needs to provide billing info.
                        // This is probably easily expressed in AOI.
                        (tuitionAndRegistrationSection === TuitionAndRegistrationSection.registeredManageBilling &&
                            subscriptionPastDue(subscription)) ||
                        tuitionAndRegistrationSection === TuitionAndRegistrationSection.specialCase;

                    return (
                        !this.relevantCohort?.supportsCohortPreApproval &&
                        this.relevantCohort?.afterRegistrationOpenDate &&
                        (this.needsToRegister || needsToProvideBillingInfo)
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'progress', {
                get() {
                    const progress = !this.$$progress?.destroyed && this.$$progress;

                    if (!progress) {
                        this.$$progress = new UserProgressLoader(this);
                    }

                    return this.$$progress;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasPlaylists', {
                get() {
                    if (this.relevantCohort && _.some(this.relevantCohort.playlistPackIds)) {
                        return true;
                    }

                    if (_.chain(this.institutions).map('playlist_pack_ids').flattenDeep().some().value()) {
                        return true;
                    }

                    return false;
                },
                configurable: true,
            });

            // Duplicates the server side unverified_for_current_id_verification_period
            Object.defineProperty(this.prototype, 'unverifiedForCurrentIdVerificationPeriod', {
                get() {
                    if (!this.relevantCohort) {
                        return false;
                    }
                    return getUnverifiedForActiveIdVerificationPeriod(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'pastDueForIdVerification', {
                get() {
                    if (!this.relevantCohort) {
                        return false;
                    }
                    return getPastDueForIdVerification(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'lastIdologyVerification', {
                get() {
                    return this.idology_verifications && this.idology_verifications[0];
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'lastIdologyVerificationFailed', {
                get() {
                    return !!(
                        this.lastIdologyVerification &&
                        this.lastIdologyVerification.response_received_at &&
                        this.lastIdologyVerification.verified === false
                    );
                },
                configurable: true,
            });

            // FIXME: Remove after we've converted necessary usages to the AppBrandConfig
            Object.defineProperty(this.prototype, 'isMiyaMiya', {
                get() {
                    return this.active_institution?.id === InstitutionId.miya_miya;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'canDownloadStreamCertificateOrAddToLinkedInProfile', {
                get() {
                    // These requirements were introduced as part of https://trello.com/c/h0ELD4ln.
                    const externalInstitutionUser = !!this.active_institution?.external;
                    const acceptedDegreeProgramUser =
                        this.isCurrentOrHasCompletedActiveProgram && Cohort.isDegreeProgram(this.programType);
                    const nonLearner = this.roleName() !== 'learner';
                    return externalInstitutionUser || acceptedDegreeProgramUser || nonLearner;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'studentEmailAddressForLastActiveAoiRecord', {
                get() {
                    const aoiRecord = getAoiRecord(this);
                    if (!aoiRecord) return null;

                    const studentEmailDomain = Cohort.studentEmailDomain(aoiRecord.programType);
                    return this.student_email_addresses?.find(address => address.domain === studentEmailDomain);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'helpScoutBeaconID', {
                get() {
                    return Cohort.helpScoutBeaconID(this.programType);
                },
            });

            Object.defineProperty(this.prototype, 'avatarUrl', {
                get() {
                    if (this.avatar_url) {
                        return regionAwareImageCdnRewriter(
                            this.avatar_url,
                            $injector.get('ConfigFactory').getSync(true),
                        );
                    }
                    return undefined;
                },
            });

            Object.defineProperty(this.prototype, 'completedStripeCheckoutSession', {
                get() {
                    const admissionOffer = getAdmissionOffer(this);
                    if (!admissionOffer) {
                        return false;
                    }
                    return ClientStorage.getItem(`completedStripeCheckoutSession:${admissionOffer.id}`) === 'true';
                },
                set(booleanValue) {
                    const admissionOffer = getAdmissionOffer(this);
                    if (!admissionOffer && getProgramInclusion(this)) {
                        return;
                    }
                    if (!admissionOffer) {
                        throw new Error(
                            `No admission offer found when setting completedStripeCheckoutSession to: ${booleanValue}`,
                        );
                    }

                    const key = `completedStripeCheckoutSession:${admissionOffer.id}`;
                    if (booleanValue) {
                        ClientStorage.setItem(key, true);
                    } else {
                        ClientStorage.removeItem(key);
                    }
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'registeredForPreviousCohort', {
                get() {
                    const admissionOffer = getAdmissionOffer(this);
                    if (!admissionOffer) return false;

                    return ClientStorage.getItem(`registeredForPreviousCohort:${admissionOffer.id}`) === 'true';
                },
                set(booleanValue) {
                    const admissionOffer = getAdmissionOffer(this);
                    if (!admissionOffer && getProgramInclusion(this)) return;

                    if (!admissionOffer) {
                        throw new Error(
                            `No admission offer found when setting registeredForPreviousCohort to: ${booleanValue}`,
                        );
                    }

                    const key = `registeredForPreviousCohort:${admissionOffer.id}`;

                    if (booleanValue) ClientStorage.setItem(key, true);
                    else ClientStorage.removeItem(key);
                },
                configurable: true,
            });

            // This supersedes the `relevant_cohort` attribute directly on the user.
            Object.defineProperty(this.prototype, 'relevantCohort', {
                get() {
                    const cohortAttrs = getCohort(this);
                    const cohort = cohortAttrs ? getIguanaCohortFromCamelCaseAttrs(cohortAttrs) : null;
                    return cohort;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'prefUserProgramState', {
                get() {
                    return getUserProgramState(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'cohortForActiveProgramInclusion', {
                get() {
                    const programInclusion = getProgramInclusion(this);
                    if (!programInclusion) return null;
                    if (willNotGraduate(programInclusion)) return null;

                    if (this.relevantCohort.id !== programInclusion.cohortId) {
                        ErrorLogService.notifyInProd(
                            'relevantCohort does not match activeProgramInclusion',
                            undefined,
                            {
                                level: 'warning',
                                activeProgramInclusionCohort: programInclusion.cohortId,
                                relevantCohort: this.relevantCohort.id,
                            },
                        );
                        return null;
                    }

                    return this.relevantCohort;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'shouldChargeCreditCardPaymentFee', {
                get() {
                    return !!getUserProgramState(this)?.admissionOfferCohortSupportsPaymentFee;
                },
                configurable: true,
            });

            this.defineSetter('pref_locale', function setPrefLocale(val) {
                if (this.pref_locale !== val) {
                    // We might have the wrong stream cached for a locale pack id
                    // if the locale changes.
                    $injector.get('Lesson.Stream').resetCache();
                    $injector.get('Playlist').resetCache();

                    this.writeKey('pref_locale', val);
                }
            });

            return {
                objectForSave() {
                    // When saving from Iguana, we don't know which context we're in, so we use the 'admin' context
                    // because doing so ensures we're saving all possible properties on the User object.
                    return getUserObjectForSave(this.asJson(), 'admin');
                },

                // global editing permissions
                canEdit() {
                    return this.roleName() === 'admin' || this.roleName() === 'super_editor';
                },

                isAuthor(lesson) {
                    if (lesson && lesson.author) {
                        return this.id === lesson.author.id;
                    }
                    return false;
                },

                // editing permissions scoped to individual lesson
                canEditLesson(lesson) {
                    if (!lesson || !lesson.editable) {
                        return false;
                    }

                    return (
                        this.canEdit() ||
                        this.isAuthor(lesson) ||
                        (this.roleName() === 'editor' && this.lessonPermissions[lesson.id] === 'lesson_editor')
                    );
                },
                canPreviewLesson(lesson) {
                    if (lesson) {
                        return (
                            this.canEdit() ||
                            this.isAuthor(lesson) ||
                            (this.roleName() === 'editor' && this.lessonPermissions[lesson.id] === 'previewer')
                        );
                    }
                    return false;
                },
                canReviewLesson(lesson) {
                    if (lesson) {
                        return (
                            this.canEdit() ||
                            this.isAuthor(lesson) ||
                            (this.roleName() === 'editor' && this.lessonPermissions[lesson.id] === 'reviewer')
                        );
                    }
                    return false;
                },
                canEditOrReviewLesson(lesson) {
                    if (lesson) {
                        return (
                            this.canEdit() ||
                            this.isAuthor(lesson) ||
                            (this.roleName() === 'editor' &&
                                (this.lessonPermissions[lesson.id] === 'lesson_editor' ||
                                    this.lessonPermissions[lesson.id] === 'reviewer'))
                        );
                    }
                    return false;
                },
                lessonAssessmentEditingLocked(lesson) {
                    return !this.canEditLesson(lesson) || lesson.assessmentEditingLocked;
                },
                canManageOwnCommentsOnLesson(lesson) {
                    if (!lesson || !lesson.editable) {
                        return false;
                    }
                    return this.canEditLesson(lesson) || this.canReviewLesson(lesson);
                },

                // this is both "can archive" and "can unarchive"
                canArchiveLesson(lesson) {
                    if (!lesson) {
                        return false;
                    }

                    if (!this.canEditLesson(lesson)) {
                        return false;
                    }

                    // if a lesson is published, then archiving will change
                    // the published status.  Check publishing rights
                    if (lesson.published_at && !this.canPublish()) {
                        return false;
                    }

                    return true;
                },

                isScopedlessonPermission(roleName) {
                    return roleName === 'lesson_editor' || roleName === 'reviewer' || roleName === 'previewer';
                },

                roleName() {
                    if (this.globalRole) {
                        return this.globalRole.name;
                    }
                    return undefined;
                },

                userGroupNames() {
                    return _.chain(this.groups).map('name').invokeMap('toUpperCase').value().sort();
                },

                inGroup(groupName) {
                    return _.includes(this.userGroupNames(), groupName.toUpperCase());
                },

                addGroup(groupName) {
                    if (this.inGroup(groupName)) {
                        return;
                    }

                    const group = {
                        name: groupName,
                    };
                    if (!this.groups) {
                        this.groups = [];
                    }
                    this.groups.push(group);
                },

                removeGroup(group) {
                    Array.remove(this.groups, group);
                },

                // Checks if the streamLocalePackId passed in is associated with any of the
                // user's access groups.
                streamIsAssociatedWithAccessGroup(streamLocalePackId) {
                    for (let i = 0; i < this.groups.length; i++) {
                        if (_.includes(this.groups[i].stream_locale_pack_ids, streamLocalePackId)) {
                            return true;
                        }
                    }
                    return false;
                },

                institutionNames() {
                    return (this.institutions || []).map(institution => institution.name).sort();
                },

                inInstitution(institutionName) {
                    return _.includes(this.institutionNames(), institutionName);
                },

                addInstitution(institution) {
                    if (!this.institutions) {
                        this.institutions = [];
                    }
                    const institutionIds = this.institutions.map(i => i.id);
                    if (!institutionIds.includes(institution.id)) {
                        this.institutions.push(institution);
                    }
                },

                removeInstitution(institution) {
                    const institutions = this.institutions || [];
                    const index = institutions.indexOf(institution);
                    if (index === -1) {
                        throw new Error(`Institution '${institution.name}' is not present on user ${this.id}`);
                    }
                    institutions.splice(index, 1);
                },

                deleteAccount() {
                    if (!this.can_delete_own_account) {
                        throw new Error('Self-serve account deletion is not permitted for this user.');
                    }

                    return $http.post(`${$window.ENDPOINT_ROOT}/api/user_actions/user/delete_account.json`, {});
                },

                changePrefProgram(upfsId) {
                    const user = this;

                    if (this.changingPrefProgram) {
                        return $q.when();
                    }

                    user.pref_user_program_field_state_id = upfsId;

                    // In the case where an admin is logged in as another user, we should allow them to change
                    // the user's program state preference, but we shouldn't save that preference to the database.
                    //
                    // FIXME: We won't be able to do this ^ without the db update unless we approach this
                    // a different way than described in https://trello.com/c/UdxyjKzf, which initially asks for a simple
                    // reload of data from the server in order to ship this quickly.
                    // if (user.ghostMode) {
                    //     return Promise.resolve();
                    // }

                    this.changingPrefProgram = true;

                    return (
                        $http
                            .post(`${$window.ENDPOINT_ROOT}/api/user_actions/user/change_pref_program.json`, {
                                user_program_field_state_id: upfsId,
                            })
                            // Navigate to dashboard after refresh to ensure the user isn't left on a route they don't have access to in the new program
                            .then(() => this.refreshUserProgramState('/dashboard'))
                            .finally(() => {
                                this.changingPrefProgram = false;
                            })
                    );
                },

                ensureAndChangePrefProgram(programType) {
                    if (this.ensuringAndChangingPrefProgram) return $q.when();

                    this.ensuringAndChangingPrefProgram = true;

                    return $http
                        .post(`${$window.ENDPOINT_ROOT}/api/user_actions/user/ensure_and_change_pref_program.json`, {
                            program_type: programType,
                        })
                        .finally(() => {
                            this.ensuringAndChangingPrefProgram = false;
                        });
                },

                offerAdmissionAlumniQuanticLaunch(programType) {
                    if (this.offeringAdmissionImmediately) return $q.when();

                    this.offeringAdmissionImmediately = true;

                    return $http
                        .post(
                            `${$window.ENDPOINT_ROOT}/api/user_actions/user/offer_admission_alumni_quantic_launch.json`,
                            { program_type: programType },
                        )
                        .finally(() => {
                            this.offeringAdmissionImmediately = false;
                        });
                },

                registerPreApprovedExecEdProgram(programType) {
                    if (this.changingPrefProgram) return $q.when();

                    this.changingPrefProgram = true;

                    // We need rejectInOfflineMode here to handle the case where this request fails with
                    // a disconnected error and the user switches to offline mode. In that case, we need
                    // to make sure we set changingPrefProgram back to false.
                    const offlineModeManager = $injector.get('offlineModeManager');
                    return offlineModeManager
                        .rejectInOfflineMode(() =>
                            $http.post(`${$window.ENDPOINT_ROOT}/api/users/exec-ed/join.json`, {
                                program_type: programType,
                            }),
                        )
                        .catch(err => {
                            if (err.constructor !== DisconnectedError) {
                                throw err;
                            }

                            // Ensure the CertCongratsModal is closed
                            DialogModal.removeAlerts();
                        })
                        .then(() => {
                            // Navigate to dashboard after refresh to ensure the user isn't left on a route they don't have access to in the new program
                            this.refreshUserProgramState('/settings/application_status');

                            // Ensure the CertCongratsModal is closed
                            DialogModal.removeAlerts();
                        })
                        .finally(() => {
                            this.changingPrefProgram = false;
                        });
                },

                refreshUserProgramState(route = '/dashboard') {
                    return LearnerContentCache.ensureStudentDashboard().then(() => {
                        NavigationHelperMixin.loadRoute(route);
                        $route.reload();
                    });
                },

                // end users use this method to apply to a cohort.
                applyToCohort(cohort, valarOptInValue) {
                    const isFirstApplication = !this.hasEverApplied;

                    // We want to know in Google Analytics when a degree application is submitted.
                    // Since Google Analytics only supports a limited number of identifying attributes on the
                    // event - an action key and a label key - we need to add the program_type to these fields
                    // so we can properly use these events when configuring goals in Google Analytics.
                    // See https://trello.com/c/fsx3KByZ for more info.
                    // As of 2020/12/30, Makis was considering using this event in FB as well, so don't
                    // assume that it is only used in GA
                    if (cohort.isDegreeProgram) {
                        EditCareerProfileHelper.logEventForApplication(
                            `${cohort.program_type}-submit-application`,
                            true,
                            {
                                label: `${cohort.program_type.toUpperCase()} Application Submitted`,
                            },
                        );
                    }

                    return $http
                        .post(`${$window.ENDPOINT_ROOT}/api/user_actions/user/submit_application.json`, {
                            cohort_id: cohort.id,
                            admissions_valar_opt_in: valarOptInValue,
                            program_family_form_datum_id: getProgramFamilyFormDatum(
                                this,
                                this.relevantCohort?.programFamily,
                            )?.id,
                        })
                        .then(response => {
                            const admissionsValarOptIn = response.data.contents.admissions_valar_opt_in;
                            const evApplicationSubmission = response.data.contents.ev_application_submission;

                            const payload = {
                                cohort_title: cohort.title,
                                s_range: this.career_profile.salaryRangeForEventPayload, // see https://trello.com/c/5jz2ZNWl

                                // see '*[emba | mba] Content Marketing Post-Application' campaign in customer.io
                                // https://fly.customer.io/env/24964/campaigns/1000133/overview?channels=email_twilio_push_webhook_slack
                                start_content_marketing_campaign: isFirstApplication,

                                // For GA -- see https://trello.com/c/GqjJaNCi
                                category: 'Submitted Application',
                                label: cohort.program_type,
                                value: evApplicationSubmission,
                                valar_opt_in: admissionsValarOptIn,
                            };

                            // FIXME: Shouldn't this be a server event to make sure it happens transactionally
                            // with application saving?
                            //
                            // Years later this question came up again, and we decided to leave it be since we
                            // have so many analytics and customer.io campaigns currently using it. And we did some investigating
                            // to find that there are users that have logged the event twice (e.g., `4f064a50-5d6f-494c-a93f-ecc9eebe794e`);
                            // but in customer.io it did not appear that the campaign, for which this event triggers an
                            // email, sent more than one copy.
                            // See https://trello.com/c/GqjJaNCi
                            EditCareerProfileHelper.logEventForApplication('submit-application', true, payload);

                            this.logConversionGoalEvent(evApplicationSubmission);
                            this.setContinueApplicationInMarketingFlag();
                        });
                },

                logConversionGoalEvent(evApplicationSubmission) {
                    const EventLogger = $injector.get('EventLogger');
                    const properties = {
                        user_id: this.id,
                        program_type: this.programType,
                        value: evApplicationSubmission,
                        currency: 'USD',
                    };

                    EventLogger.log(
                        'cohort:conversion_goal',
                        { label: 'Purchase', ...properties },
                        { log_to_server_conversions_segment: true },
                    );

                    // Note: This is a duplicate event of 'cohort:conversion_goal'.
                    // We map certain events to Facebook events in Segment (e.g., `cohort:conversion_goal` to `SubmitApplication` and `user:logged_in_first_time` to `Lead`).
                    // We need to also map these events to Facebook `Purchase` events, but Segment only supports 1-to-1 mapping, so we
                    // create a second duplicate event to map.
                    EventLogger.log('cohort:conversion_goal_purchase', properties, {
                        log_to_server_conversions_segment: true,
                    });
                },

                canPublish() {
                    return this.roleName() === 'admin';
                },

                toggleBookmark(stream) {
                    stream.favorite = !stream.favorite;

                    // we actually want to allow the toggle to occur immediately, with
                    // the save prep and handling to execute asynchronously
                    $timeout(() => {
                        // ensure the user has favorite_lesson_stream_locale_packs collection
                        this.favorite_lesson_stream_locale_packs = this.favorite_lesson_stream_locale_packs || [];

                        // produce simple lookup map of unique locale packs
                        const existingFavoriteIds = _.chain(this.favorite_lesson_stream_locale_packs)
                            .map('id')
                            .uniq()
                            .value();

                        // remove or add the stream locale pack
                        const index = existingFavoriteIds.indexOf(stream.locale_pack.id);
                        if (index > -1) {
                            this.favorite_lesson_stream_locale_packs.splice(index, 1);
                        } else {
                            this.favorite_lesson_stream_locale_packs.push({
                                id: stream.locale_pack.id,
                            });
                        }

                        // update the user
                        this.save();

                        frontRoyalStore.setStreamBookmarks(this);
                    });
                },

                clearAllProgress() {
                    const user = this;

                    return $http
                        .delete(`${$window.ENDPOINT_ROOT}/api/destroy_all_progress.json`)
                        .then(() => frontRoyalStore.retryRequestOnHandledError('clearProgressForUser', this.id))
                        .then(() => {
                            user.favorite_lesson_stream_locale_packs = [];
                        });
                },

                avatarUploadUrl() {
                    return `${window.ENDPOINT_ROOT}/api/users/${this.id}/upload_avatar.json`;
                },

                resumeUploadUrl() {
                    return `${window.ENDPOINT_ROOT}/api/users/${this.id}/upload_resume.json`;
                },

                // This method logs the onboarding:complete event the
                // first time the user is routed to a page within front-royal.
                // See ensureLoginEvent for something similar
                ensureHasSeenWelcome() {
                    const user = this;
                    if (user.has_seen_welcome || user.ghostMode) {
                        return;
                    }

                    const EventLogger = $injector.get('EventLogger');
                    EventLogger.log('onboarding:complete', { user_id: user.id });

                    user.has_seen_welcome = true;
                    user.save();
                },

                // This method logs `user:logged_in_first_time` the first time
                // a user logs in.  This can be logged even when onboarding:complete is
                // not logged if the user completes the signup form at dynamic_landing_page
                // but never actually goes into the app (see ensureHasSeenWelcome)
                ensureLoginEvent() {
                    const user = this;
                    const utmMap = ClientStorage.getCookie('utmMap');
                    const marketingSignupUrl = ClientStorage.getCookie('marketingSignupUrl');
                    const EventLogger = $injector.get('EventLogger');
                    let context;

                    // This method is not idempotent and can result in duplicate events. See comment in
                    // `ValidationResponder.handleValidationSuccess` for why this can get called multiple
                    // times and why this check is necessary.
                    if (user.has_logged_in || user.ghostMode) return;

                    user.has_logged_in = true;
                    user.li_fat_id = Cookies.get('li_fat_id') || Cookies.get('scraped_li_fat_id');

                    $q.all([sha256(user.email), sha256(user.phone)])
                        .then(sha256Values => {
                            const payload = {
                                gl: preSignupValues.isGoodLead(),
                                value: user.ev_signup,

                                // For Google Ads enhanced conversions
                                // See https://support.google.com/google-ads/answer/13262500#zippy=%2Cidentify-and-define-your-enhanced-conversions-variables
                                sha256_email_address: user.email && sha256Values[0],
                                sha256_phone_number: user.phone && sha256Values[1],
                                country: window.cloudflareCountryCode,

                                utm_source: utmMap?.utm_source,
                                utm_content: utmMap?.utm_content,
                                utm_campaign: utmMap?.utm_campaign,
                                utm_medium: utmMap?.utm_medium,
                                utm_term: utmMap?.utm_term,
                                ad_network: utmMap?.ad_network,

                                cf_city: window.cf_geo?.cf_city,
                                cf_country_code: window.cf_geo?.cf_country_code,
                                cf_metro_code: window.cf_geo?.cf_metro_code,
                                cf_postal_code: window.cf_geo?.cf_postal_code,
                                cf_region: window.cf_geo?.cf_region,
                            };

                            // In marketing we capture the signup url for overriding the url property and Segment context in these signup events.
                            // This allows internal reports and third-party integrations to differentiate between landing pages.
                            // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
                            if (marketingSignupUrl) {
                                const url = new URL(marketingSignupUrl);

                                payload.url = url.href;
                                context = { page: { path: url.pathname, url: url.href, search: url.search } };
                            }

                            EventLogger.log('user:logged_in_first_time', payload, {
                                log_to_customerio: true,
                                log_to_server_conversions_segment: true,
                                context,
                            });

                            // Note: This is a duplicate event of 'user:logged_in_first_time'.
                            // We map internal events to standard marketing destination events in Segment (e.g., Lead and Purchase).
                            // We need to map one internal event to multiple standard events, but Segment only supports 1-to-1 mapping, so we
                            // create a second duplicate event to map.
                            EventLogger.log('user:logged_in_first_time_purchase', payload, {
                                log_to_server_conversions_segment: true,
                                context,
                            });

                            // Note: This is a duplicate, flattened event of 'user:logged_in_first_time' when attribute `gl: true`.
                            if (payload.gl) EventLogger.log('user:logged_in_first_time_gl', payload, { context });

                            this._checkMadrivoConversion(user, utmMap, marketingSignupUrl);
                            this._checkMyDegreeConversion(utmMap, marketingSignupUrl);

                            EventLogger.tryToSaveBuffer();

                            return user.save();
                        })
                        .then(() => {
                            const utmMapCookie = ClientStorage.getCookie('utmMap') ?? {};
                            // Now that we've used the preSignupValues, it's safe to clear them out
                            preSignupValues.clear(); // Clears cookie and localStorage
                            // Store the UTM map in localStorage for use in the app
                            ClientStorage.setItem('utmMap', JSON.stringify(utmMapCookie));
                        })
                        .catch(e => ErrorLogService.notify(e.message));
                },

                _checkMadrivoConversion(user, utmMap, marketingSignupUrl) {
                    // See https://trello.com/c/Jdpbyk4u
                    const clickId = utmMap?.utm_content;
                    const logMadrivoConversion =
                        marketingSignupUrl?.includes('/invite/mdo') &&
                        utmMap?.utm_source === 'mdo' &&
                        clickId &&
                        preSignupValues.isMadrivoQualified();
                    if (logMadrivoConversion) {
                        const params = {
                            nid: 1405,
                            transaction_id: clickId,
                            adv1: user.id,
                        };
                        window
                            .fetch(`https://www.plotorope.com?${new URLSearchParams(params)}`)
                            .catch(e => ErrorLogService.notify(e.message, undefined, { params }));
                    }
                },

                _checkMyDegreeConversion(utmMap, marketingSignupUrl) {
                    // See https://trello.com/c/KBtCDRGf
                    const utmReqId = utmMap?.utm_reqid;
                    const utmAffId = utmMap?.utm_affid;
                    const logMyDegreeConversion =
                        marketingSignupUrl?.includes('/invite/mydegree') &&
                        utmMap?.utm_source === 'mydegree' &&
                        utmReqId &&
                        utmAffId;
                    if (logMyDegreeConversion) {
                        const params = {
                            r: utmReqId,
                            utm_affid: utmAffId,
                            e: 1130,
                        };
                        window
                            .fetch(`https://mydegreeapi.com/api/postback/firepixel?${new URLSearchParams(params)}`)
                            .catch(e => ErrorLogService.notify(e.message, undefined, { params }));
                    }
                },

                userIdVerificationForPeriod(idVerificationPeriod) {
                    // FIXME: do we need caching here?
                    return _.find(this.user_id_verifications, userIdVerification =>
                        userIdVerification.forVerificationPeriod(idVerificationPeriod),
                    );
                },

                addUserIdVerification(verificationPeriod) {
                    const userIdVerification = UserIdVerification.new({
                        cohort_id: verificationPeriod.cohortId,
                        id_verification_period_index: verificationPeriod.index,
                        user_id: this.id,
                        verification_method: 'verified_by_admin',
                    });
                    userIdVerification.$$embeddedIn = this;
                    this.user_id_verifications = this.user_id_verifications || [];
                    this.user_id_verifications.push(userIdVerification);
                },

                removeUserIdVerification(verificationPeriod) {
                    const userIdVerification = this.userIdVerificationForPeriod(verificationPeriod);
                    this.user_id_verifications = this.user_id_verifications.filter(
                        verification => verification !== userIdVerification,
                    );
                },

                setContinueApplicationInMarketingFlag() {
                    if (
                        this.roleName() === 'learner' &&
                        !this.hasEverApplied &&
                        !_.includes(['demo', 'external'], this.programType)
                    ) {
                        ClientStorage.setItem('continueApplicationInMarketing', true);
                    } else {
                        ClientStorage.removeItem('continueApplicationInMarketing');
                    }
                },

                requestIdologyLink(params) {
                    // params should include a value for either `email` or `phone`
                    return $http.post(`${$window.ENDPOINT_ROOT}/api/idology/request_idology_link.json`, {
                        ...params,
                        user_id: this.id,
                        cohort_id: getProgramInclusion(this).cohortId,
                    });
                },

                streamIsAssociatedWithRelevantCohort(streamLocalePackId) {
                    return !!(this.relevantCohort && this.relevantCohort.stream_locale_packs_info[streamLocalePackId]);
                },

                streamIsAssociatedWithInstitution(streamLocalePackId) {
                    return !!this.institutions?.find(institution =>
                        institution.playlist_pack_ids.find(playlistPackId => {
                            const playlist = Playlist.getCachedForLocalePackId(playlistPackId);
                            return playlist.streamLocalePackIds.includes(streamLocalePackId);
                        }),
                    );
                },
            };
        });
    },
]);
