// FIXME
import deepmerge from 'deepmerge';

// See webpack/loaderHelpers/README.md for an overview of how locales are built
export default function setSpecLocales(...objs) {
    if (objs.length > 1) {
        objs.forEach(obj => setSpecLocales(obj));
        return;
    }
    const obj = objs[0];

    // obj looks like {en: {my_module: {my_file: {key: 'Translation'}}}}
    window.locales = window.locales || {};
    window.locales = deepmerge(window.locales, obj);
}
