// eslint-disable-next-line import/no-restricted-paths
import { type CurrentUserIguanaObject } from 'Users';
import { SegmentioHelper } from 'Analytics';
import { statsigClientProvider } from 'StatsigClientProvider';
import { type StatsigUser } from '@statsig/js-client';
import sha256 from './sha256';

export default async function identifyUser(user: CurrentUserIguanaObject) {
    if (!user) return;

    // Identify in Segment
    window.analytics?.identify(user.id, SegmentioHelper.getIdentifyPayload(user));

    // Push attributes to the GTM data layer
    const sha256Values = await Promise.all([sha256(user.email), sha256(user.phone)]);
    window.dataLayer?.push({
        sha256_email_address: sha256Values[0],
        sha256_phone_number: sha256Values[1],
    });

    // Update Statsig user
    const statsigUser: StatsigUser = {
        userID: user.id,
        custom: {
            sign_up_code: user.sign_up_code || '',
            cordova: !!window.CORDOVA,
        },
        privateAttributes: {
            email: user.email || '',
        },
    };
    statsigClientProvider.require().updateUserSync(statsigUser);
}
