import { FormProvider, useForm, yupResolver } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { memo, useEffect } from 'react';
import { Select, TextField, theme } from 'FrontRoyalMaterialUiForm';
import { ThemeProvider } from '@mui/material';
import { type PreviousLessonOption, type VoiceCoursePracticeSettings } from './VoiceCoursePractice.types';

const validationSchema = Yup.object().shape({
    agentIdentifier: Yup.string().required(),
    threshold: Yup.number().required(),
    prefixPaddingMs: Yup.number().required(),
    silenceDurationMs: Yup.number().required(),
});

const agents = [
    { identifier: 'courseTopics', label: 'Work through a list of topics related to the course.' },
    { identifier: 'courseOutline', label: 'Have a less structured conversation based on the course outline.' },
    { identifier: 'reviewPreviousMaterial', label: 'Recap Lesson' },
];

type Props = {
    settings: VoiceCoursePracticeSettings;
    setSettings: (settings: VoiceCoursePracticeSettings) => void;
    previousLessonOptions: PreviousLessonOption[];
};

function AgentIdentifierInput() {
    return (
        <Select
            fullWidth
            label="Agent"
            name="agentIdentifier"
            options={agents}
            optionLabel={a => a.label}
            optionValue={a => a.identifier}
        />
    );
}

function NumTopicsInput() {
    return (
        <Select
            label="Number of topics"
            name="numTopics"
            fullWidth
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            optionLabel={opt => opt.toString()}
        />
    );
}

function LessonInput({ previousLessonOptions }: { previousLessonOptions: Props['previousLessonOptions'] }) {
    return (
        <Select
            label="Lesson"
            name="nextLessonId"
            fullWidth
            options={previousLessonOptions}
            optionLabel={opt => opt.previousLessonTitle}
            optionValue={opt => opt.nextLessonId}
        />
    );
}

function NumberInput({
    name,
    min,
    max,
    step,
    label,
    title,
}: {
    name: string;
    label: string;
    title: string;
    min: number;
    max: number;
    step: number;
}) {
    return (
        <TextField
            name={name}
            label={label}
            title={title}
            type="number"
            fullWidth
            inputProps={{
                min,
                max,
                step,
                className: 'p-[15px]', // This is a hack to make up for the fact that our mui stuff doesn't play nice with our tailwind stuff right now
            }}
        />
    );
}

function ThresholdInput() {
    return (
        <NumberInput
            name="threshold"
            label="Threshold"
            title="Adjusts the sensitivity of the VAD. A lower threshold makes the VAD more sensitive to speech (detects quieter sounds), while a higher threshold makes it less sensitive."
            min={0}
            max={1}
            step={0.1}
        />
    );
}

function PrefixPaddingInput() {
    return (
        <NumberInput
            name="prefixPaddingMs"
            label="Prefix Padding (ms)"
            title="Minimum duration of speech (in milliseconds) required to start a new speech chunk. This helps prevent very short sounds from triggering speech detection."
            min={0}
            max={5000}
            step={100}
        />
    );
}

function SilenceDurationInput() {
    return (
        <NumberInput
            name="silenceDurationMs"
            label="Silence Duration (ms)"
            title="Minimum duration of silence (in milliseconds) at the end of speech before ending the speech segment. This ensures brief pauses do not prematurely end a speech segment."
            min={0}
            max={5000}
            step={100}
        />
    );
}

function SettingsFormComponent({ settings, setSettings, previousLessonOptions }: Props) {
    const formFunctions = useForm<VoiceCoursePracticeSettings>({
        defaultValues: settings,
        resolver: yupResolver(validationSchema),
    });
    const { handleSubmit, watch, getValues } = formFunctions;

    // submit the form on evert field change, updating the settings object
    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(
                newValues => {
                    setSettings({ ...newValues });
                },
                errors => {
                    console.error('Invalid form ', errors);
                },
            )();
        });

        return () => subscription.unsubscribe();
    }, [handleSubmit, setSettings, watch, getValues]);

    return (
        <ThemeProvider theme={theme}>
            <FormProvider {...formFunctions}>
                <form className="m-4 bg-white p-2 text-black">
                    <div className="flex flex-col gap-4">
                        <h2>Settings</h2>
                        <div className="flex items-center gap-4">
                            <AgentIdentifierInput />
                            {settings.agentIdentifier === 'courseTopics' && <NumTopicsInput />}
                            {settings.agentIdentifier === 'reviewPreviousMaterial' && (
                                <LessonInput previousLessonOptions={previousLessonOptions} />
                            )}
                        </div>

                        {/* New row for ThresholdInput, PrefixPaddingInput, and SilenceDurationInput */}
                        <div className="flex items-center gap-4">
                            <ThresholdInput />
                            <PrefixPaddingInput />
                            <SilenceDurationInput />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </ThemeProvider>
    );
}

export const SettingsForm = memo(SettingsFormComponent) as typeof SettingsFormComponent;
