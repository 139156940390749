/* eslint-disable max-lines-per-function */
import { type AnyObject, type Nullable } from '@Types';
import { useErrorLogService } from 'ErrorLogging';
import { useSyncConfig } from 'FrontRoyalConfig';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import transformKeyCase from 'Utils/transformKeyCase';
import { storeProvider } from 'ReduxHelpers';
import {
    chatActions,
    getActiveConversationId,
    getActiveConversationMessages,
    getMessageFromMessageId,
    getStreamingAiMessage,
    type RootStateWithChatSlice,
} from '../redux/chat';

import { useWebSocket } from './useWebSocket';
import {
    type IncomingChatSocketMessage,
    type ClientToSocketMessage,
    type RealAiMessage,
    type BotClientContext,
    type OutgoingMessage,
    type ChatMessageForApi,
    type CancellationTrigger,
} from '../TutorBot.types';
import { createRealAiMessage, isOutgoingMessage } from '../utils/ChatMessage';

/**
 * A hook to submit a message/question to TutorBot over a WebSocket connection and update
 * the chat state in the Redux store as the response events stream in.
 *
 */
export function useAskTutorBot(clientContext: BotClientContext) {
    const dispatch = useDispatch();
    const config = useSyncConfig();
    const ErrorLogService = useErrorLogService();
    const hostname = window.location.hostname;
    const origin = useMemo(() => {
        if (hostname.includes('.cn')) {
            return config.tutorBotCnOrigin();
        }
        if (hostname.includes('smart.ly') || hostname.includes('smartly')) {
            return config.tutorBotSmartlyOrigin();
        }
        return config.tutorBotOrigin();
    }, [hostname, config]);

    const activeConversationId = useSelector(getActiveConversationId);
    const chatHistory = useSelector(getActiveConversationMessages);
    const streamingAiMessage = useSelector(getStreamingAiMessage);
    const currentMessageIdRef = useRef<string | null>(null);

    /* Parse the socket message event data (JSON string) and convert to camelCase */
    const transformSocketMessage = useCallback(
        (data: string) => transformKeyCase(JSON.parse(data), { to: 'camelCase' }) as IncomingChatSocketMessage,
        [],
    );

    /* Convert a message to snake_case before sending to the server */
    const transformClientMessage = useCallback(
        (json: AnyObject) => JSON.stringify(transformKeyCase(json, { to: 'snakeCase' }) as ClientToSocketMessage),
        [],
    );

    const handleSocketClose = useCallback(() => {
        if (!activeConversationId || !currentMessageIdRef.current) return;

        ErrorLogService.notify(`Tutorbot websocket closed unexpectedly`, null, {
            activeConversationId,
        });

        handleErrorMessage(activeConversationId, currentMessageIdRef.current);
    }, [ErrorLogService, activeConversationId]);

    /* Set up the interface for a WebSocket to the TutorBot '/chat' endpoint */
    const { open, send, close, addListener, removeListener } = useWebSocket({
        transformSocketMessage,
        transformClientMessage,
        handleSocketClose,
    });

    /* Handle incoming socket messages based on their event type */
    const handleMessage = useCallback(
        async (socketMessage: IncomingChatSocketMessage) => {
            if (!socketMessage || !activeConversationId) return;

            /* Use the unique value tbRunId from the socket-to-client messages to ensure we update the right message locally */
            const messageId = socketMessage.meta.tbRunId;
            const eventType = socketMessage.eventType;

            currentMessageIdRef.current = messageId || null;

            switch (eventType) {
                case 'END': {
                    const status = { key: 'finished' as const };
                    dispatch(chatActions.mergeAiMessageAttrs({ messageId, attrs: { complete: true, status } }));
                    await close();
                    break;
                }
                case 'ERROR': {
                    ErrorLogService.notify(
                        `Received error from tutorbot api: ${socketMessage?.payload?.message || 'No message'}`,
                        null,
                        {
                            activeConversationId,
                        },
                    );

                    handleErrorMessage(activeConversationId, messageId);

                    await close();
                    break;
                }
                case 'START': {
                    const { version, context, algorithm } = socketMessage.meta;
                    dispatch(
                        chatActions.mergeAiMessageAttrs({
                            messageId,
                            attrs: {
                                aiMessageMetadata: {
                                    botVersion: version,
                                    algorithm,
                                } as RealAiMessage['aiMessageMetadata'],
                                conversationContext: context,
                            },
                        }),
                    );
                    break;
                }
                case 'TOKEN_FOR_DISPLAY': {
                    dispatch(
                        chatActions.appendContentToken({
                            messageId,
                            token: socketMessage.payload.token as string,
                            now: Date.now(),
                        }),
                    );
                    break;
                }
                case 'STATUS_UPDATE': {
                    const status = { key: socketMessage.payload.status } as RealAiMessage['status'];
                    dispatch(chatActions.mergeAiMessageAttrs({ messageId, attrs: { status } }));
                    break;
                }
                case 'COST': {
                    const {
                        totalTokens,
                        totalTokensFromCache,
                        promptTokens,
                        promptTokensFromCache,
                        completionTokens,
                        completionTokensFromCache,
                        totalCost,
                        totalCostFromCache,
                    } = socketMessage.payload;
                    const aiMessageMetadata = {
                        totalTokens,
                        totalCostFromCache,
                        promptTokens,
                        promptTokensFromCache,
                        completionTokens,
                        completionTokensFromCache,
                        totalCost,
                        totalTokensFromCache,
                    } as RealAiMessage['aiMessageMetadata'];
                    dispatch(chatActions.mergeAiMessageAttrs({ messageId, aiMessageMetadata }));
                    break;
                }
                case 'RESULT': {
                    const { sources, answer, footnoteReferences } = socketMessage.payload;
                    dispatch(
                        chatActions.mergeAiMessageResult({
                            messageId,
                            sources,
                            footnoteReferences,
                            answer,
                            completedAt: Date.now(),
                        }),
                    );
                    break;
                }
                default:
                /* no-op */
            }
        },
        [close, dispatch, activeConversationId, ErrorLogService],
    );

    const closeSocket = useCallback(async () => {
        await close();
    }, [close]);

    const hideIncompleteMessages = useCallback(() => {
        dispatch(chatActions.hideIncompleteMessages());
    }, [dispatch]);

    /* Log a WebSocket connection error to Sentry */
    const handleWsConnectionError = useCallback(
        (aiMessageId: string, err: unknown) => {
            // eslint-disable-next-line no-console
            console.error(err);
            dispatch(chatActions.mergeAiMessageAttrs({ messageId: aiMessageId, attrs: { hasError: true } }));
            closeSocket();
            hideIncompleteMessages();
        },
        [dispatch, closeSocket, hideIncompleteMessages],
    );

    /* Open the TutorBot chat WebSocket, and send the user's message */
    const sendMessage = useCallback(
        async ({
            message: outgoingMessage,
            relevantCohortId,
        }: {
            message: OutgoingMessage;
            // relevantCohortId is null for institutional users and not required in lesson_player ctx
            relevantCohortId: Nullable<string>;
        }) => {
            if (!relevantCohortId && clientContext.uiContext === 'bot_page') {
                throw new Error('Cannot send a message without a relevantCohortId');
            }
            if (!activeConversationId) {
                throw new Error('Cannot send a message without an activeConversationId');
            }
            if (activeConversationId !== outgoingMessage.conversationId) {
                throw new Error('Cannot send a message that is not part of the active conversation');
            }

            /* Add the client message to the local chat history */
            dispatch(chatActions.addMessage({ message: outgoingMessage }));

            /* Add the skeleton AI response message to the store, which
               we will update when the response data streams in */
            const responseMessage: RealAiMessage = createRealAiMessage({ conversationId: activeConversationId });
            // skip logging on the initial message action, wait for it to finish streaming
            dispatch(chatActions.addMessage({ message: responseMessage, skipLogging: true }));

            const messagesForApi: ChatMessageForApi[] = chatHistory.concat([outgoingMessage]).map(m => ({
                content: m.content,
                role: m.role,
                payload: isOutgoingMessage(m) ? m.payload : null,
            }));

            /* Open a WebSocket to TutorBot */
            open(
                _getChatSocketUrl({
                    tutorBotOrigin: origin,
                    messageId: responseMessage.id,
                    cohortId: relevantCohortId,
                    uiContext: clientContext.uiContext,
                }).toString(),
            )
                .then(() => {
                    /* Send the message to the server via the socket */
                    const messageToSocket: ClientToSocketMessage = {
                        eventType: 'ASK',
                        payload: {
                            messages: messagesForApi,
                            clientContext,
                        },
                    };
                    send(messageToSocket);
                })
                .catch(err => handleWsConnectionError(responseMessage.id, err));
        },
        [open, send, dispatch, activeConversationId, origin, handleWsConnectionError, clientContext, chatHistory],
    );

    const cancelCurrentStreamingMessage = useCallback(
        async (cancellationTrigger: CancellationTrigger) => {
            await closeSocket(); // Still close the websocket even if there is no streaming message yet
            if (streamingAiMessage) {
                dispatch(
                    chatActions.mergeAiMessageAttrs({
                        messageId: streamingAiMessage!.id,
                        attrs: { complete: true, canceled: cancellationTrigger },
                    }),
                );
            } else {
                // AI message may be loading but not yet streaming
                hideIncompleteMessages();
            }
        },
        [closeSocket, streamingAiMessage, hideIncompleteMessages, dispatch],
    );

    /* Listen for WebSocket events */
    useEffect(() => {
        addListener(handleMessage);
        return () => removeListener(handleMessage);
    }, [addListener, handleMessage, removeListener]);

    return { sendMessage, closeSocket, hideIncompleteMessages, cancelCurrentStreamingMessage };
}

function handleErrorMessage(conversationId: string, messageId: string) {
    const { dispatch, getState } = storeProvider.store!;
    const state = getState() as RootStateWithChatSlice;

    const existingMessage = getMessageFromMessageId(state, messageId);

    // If the socket errored before a message was created and added to the store, we need to dispatch a new message
    if (!existingMessage) {
        const message = createRealAiMessage({ conversationId });
        dispatch(chatActions.addMessage({ message: { ...message, hasError: true, complete: true } }));
        return;
    }

    dispatch(chatActions.mergeAiMessageAttrs({ messageId, attrs: { hasError: true, complete: true } }));
}

/* Build the /chat socket endpoint URL based on the configured TutorBot origin URL */
function _getChatSocketUrl({
    tutorBotOrigin,
    messageId,
    cohortId,
    uiContext,
}: {
    tutorBotOrigin: string;
    messageId: string;
    cohortId: Nullable<string>;
    uiContext: BotClientContext['uiContext'];
}): URL {
    const url = new URL(tutorBotOrigin);
    /* determine whether to use `wss` or `ws` in this environment */
    url.protocol = ['https:', 'wss:'].includes(url.protocol) ? 'wss:' : 'ws:';
    url.pathname = '/chat';
    if (window.CORDOVA) {
        // In Cordova, send auth values as query params to TutorBot so it can auth with BackRoyal without cookies
        const authHeaders = JSON.parse(window.localStorage.getItem('auth_headers') || '{}');
        const searchParamsStr = Object.keys(authHeaders).reduce(
            (str, headerKey) => `${str}${str ? '&' : ''}${headerKey}=${encodeURIComponent(authHeaders[headerKey])}`,
            '',
        );
        url.search = `?${searchParamsStr}`;
    }
    const queryParams = `message-id=${messageId}&cohort-id=${cohortId}&ui-context=${uiContext}`;

    url.search += url.search.includes('?') ? `&${queryParams}` : `?${queryParams}`;
    return url;
}
