import '@livekit/components-styles';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { type DataMessage, VoiceAgentRoom } from 'Livekit';
import { useDispatch, useSelector } from 'react-redux';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons/faSquareCheck';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTopics } from './voiceCoursePracticeSelectors';
import {
    type VoiceCoursePracticeSettings,
    type VoiceCoursePracticeAttributes,
    type PracticeTopic,
    type PreviousLessonOption,
} from './VoiceCoursePractice.types';
import { isTopicsUpdatedMessage } from './DataMessage';
import { voiceCoursePracticeSlice } from './voiceCoursePracticeSlice';
import { SettingsForm } from './SettingsForm';

// These values are duplicated in turor_bot_voice_agent.py
const DEFAULT_THRESHOLD = 0.7; // livekit default is 0.5
const DEFAULT_PREFIX_PADDING_MS = 300; // livekit default is 300
const DEFAULT_SILENCE_DURATION_MS = 750; // livekit default is 500

const {
    actions: { setTopics },
} = voiceCoursePracticeSlice;

type Props = {
    tokenAttributes: Omit<VoiceCoursePracticeAttributes, 'agentIdentifier'>;
    previousLessonOptions: PreviousLessonOption[];
};

function topicIsActive(topic: PracticeTopic) {
    return topic.started && !topic.reviewed;
}

function Topic({ topic }: { topic: PracticeTopic }) {
    const active = useMemo(() => topicIsActive(topic), [topic]);

    return (
        <div className="flex items-center">
            <FontAwesomeIcon icon={topic.reviewed ? faSquareCheck : faSquare} className="mr-2" />
            <span className={active ? 'underline' : ''}>{topic.title}</span>
        </div>
    );
}

function getDefaultSettings(previousLessonOptions: Props['previousLessonOptions']): VoiceCoursePracticeSettings {
    const nextLessonId = previousLessonOptions[0]?.nextLessonId;
    const turnDetection = {
        threshold: DEFAULT_THRESHOLD,
        prefixPaddingMs: DEFAULT_PREFIX_PADDING_MS,
        silenceDurationMs: DEFAULT_SILENCE_DURATION_MS,
    };
    if (!nextLessonId) {
        return {
            agentIdentifier: 'courseTopics',
            numTopics: '5',
            ...turnDetection,
        };
    }

    return {
        agentIdentifier: 'reviewPreviousMaterial',
        nextLessonId,
        ...turnDetection,
    };
}

function VoiceCoursePracticeComponent({ tokenAttributes: incomingTokenAttributes, previousLessonOptions }: Props) {
    const [settings, setSettings] = useState<VoiceCoursePracticeSettings>(getDefaultSettings(previousLessonOptions));
    const tokenAttributes: VoiceCoursePracticeAttributes = useMemo(
        () => ({ ...incomingTokenAttributes, ...settings }),
        [incomingTokenAttributes, settings],
    );
    const topics = useSelector(getTopics);
    const dispatch = useDispatch();

    const onDataReceived = useCallback(
        (message: DataMessage) => {
            if (isTopicsUpdatedMessage(message)) {
                dispatch(setTopics(message.payload.topics));
            }
        },
        [dispatch],
    );

    useEffect(() => {
        if (settings.agentIdentifier === 'courseTopics' && !settings.numTopics) {
            setSettings({ ...settings, numTopics: '5' });
        }
    }, [settings]);

    return (
        <div data-lk-theme="default" className="grid h-full content-center bg-[var(--lk-bg)]">
            <SettingsForm settings={settings} setSettings={setSettings} previousLessonOptions={previousLessonOptions} />

            <VoiceAgentRoom tokenAttributes={tokenAttributes} onDataReceived={onDataReceived} />

            <div className="flex flex-col space-y-2 pl-2">
                {topics?.map(topic => (
                    <Topic key={topic.title} topic={topic} />
                ))}
            </div>
        </div>
    );
}

export const VoiceCoursePractice = memo(VoiceCoursePracticeComponent) as typeof VoiceCoursePracticeComponent;
