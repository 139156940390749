import clsx from 'clsx';
import React, { forwardRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { type LaunchBotButtonProps } from './AiAdvisorTooltip.types';

const REVIEW_THIS_LESSON_BUTTON_KEY = 'review_this_lesson';
const EXPLAIN_BUTTON_KEY = 'explain';
const REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY = 'recap_last_lesson';

const botLaunchButtonKeys = [
    REVIEW_THIS_LESSON_BUTTON_KEY,
    EXPLAIN_BUTTON_KEY,
    REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY,
] as const;

export const LaunchBotButton = forwardRef<HTMLButtonElement, LaunchBotButtonProps>(
    ({ backwardsClass, continueButtonVisible, buttonKey, launchBot }, ref) => {
        const { t } = useTranslation('back_royal', { keyPrefix: 'lessons.lesson' });

        return (
            <>
                {botLaunchButtonKeys.map(key => (
                    <Fragment key={key}>
                        {key === buttonKey ? (
                            <button
                                type="button"
                                ref={ref}
                                className={clsx(
                                    'launch-bot-button flat white-and-beige-outline animated flex',
                                    'items-center justify-center px-4 py-2.5',
                                    backwardsClass,
                                    { 'showing-continue-button': continueButtonVisible },
                                )}
                                onClick={() => launchBot()}
                            >
                                <i className="fa-solid fa-sparkles" />
                                <div className="ms-2 flex flex-col items-start justify-start leading-[.8]">
                                    <span className="mb-[4px] justify-start text-xxs font-normal uppercase">
                                        {t('show_frames_player.aiTutor', { ai: 'ai' })}
                                    </span>

                                    <span>{t(`show_frames_player.${buttonKey}`)}</span>
                                </div>
                            </button>
                        ) : null}
                    </Fragment>
                ))}
            </>
        );
    },
);
